import React, { useContext, useState } from 'react';
import { useForm, Controller } from "react-hook-form"

import Loading from '../../components/loading';
import Input from '../../components/Input';
import { validarCNPJ } from '../../utils/validators';
import { ExternalService } from '../../services/external';
import { apiRevenda } from '../../services';
import { toast } from 'react-toastify';
import Modal from '../../components/modal';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const RegisterAccoutant = (props) => {

    const firstColor = "#f17f38"

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const [loading, setLoading] = useState(false);
    const [loadingGetCNPJ, setLoadingGetCNPJ] = useState(false);

    const [id_cidade, setid_cidade] = useState(false);

    const [showPass, setShowPass] = useState(false);

    const [observacao, setObservacao] = useState('');

    const handleCreate = (data) => {
        setLoading(true)
        const RgAccountant = localStorage.getItem("cadastro_contador")
        const RgAccountantParse = JSON.parse(RgAccountant)
        if (!RgAccountantParse?.token_uuid) {
            toast.error('Não foi possível validar token, tente os passos novamente!', 3000, 'success');
            return
        }
        apiRevenda.post(`contadores/create?token_uuid=${RgAccountantParse?.token_uuid}`, {
            ...data,
            observacao,
            ibge: id_cidade,
            cnpj: data?.cnpj?.replace(/\D/g, '', '')
        })
            .then(() => {
                toast.success('Contador registrado com sucesso!', 3000, 'success');
                reset();
                resetFilds();
            })
            .catch(() => toast.error('Erro ao registrar contador!', 3000, 'error'))
            .finally(() => {
                setLoading(false)
            })
    }

    const GetCEP = (cep) => {
        ExternalService.getCep(cep)
            .then(({ data }) => {
                const { logradouro, bairro, localidade, ibge } = data;
                setValue("bairro", bairro)
                setValue("cidade", localidade)
                setValue("logradouro", logradouro)
                setid_cidade(ibge)
            })
    }

    const GetCNPJ = (cnpj) => {
        setLoadingGetCNPJ(true);
        ExternalService.cnpj(cnpj)
            .then(({ data }) => {
                const { razao_social: razao, estabelecimento, socios } = data;
                setValue("razao_social", razao ?? "");
                setValue("fantasia", estabelecimento?.nome_fantasia ?? "");
                setValue("email", estabelecimento?.email ?? "");
                setValue("cep", estabelecimento?.cep ?? "");
                setValue("cidade", estabelecimento?.cidade?.nome ?? "");
                setid_cidade(estabelecimento?.cidade?.ibge_id);
                setValue("logradouro", estabelecimento?.logradouro);
                setValue("complemento", estabelecimento?.complemento ?? "");
                setValue("bairro", estabelecimento?.bairro ?? "");
                setValue("numero", estabelecimento?.numero ?? "");
                setValue("celular", estabelecimento?.ddd1 + estabelecimento?.telefone1 ?? "");
                setValue("contato", socios[0]?.nome ?? "");
            })
            .catch(() => {

            })
            .finally(() => setLoadingGetCNPJ(false))
    }

    const resetFilds = () => {
        setValue("razao_social", "");
        setValue("fantasia", "");
        setValue("email", "");
        setValue("cep", "");
        setValue("cidade", "");
        setid_cidade(false);
        setValue("logradouro", "");
        setValue("complemento", "");
        setValue("bairro", "");
        setValue("numero", "");
        setValue("celular", "");
        setValue("contato", "");
    }


    return (
        <div className="w-100 flex gap-5 flex-col items-center" style={{ height: "100vh", width: "100vw", backgroundColor: "white" }}>
            <div className="recovery-pass-header">
                <div className="container-recovery">
                    <img src="/img/integraHotline.png" alt="Logo" style={{ width: 135 }} />
                </div>
            </div>
            <div className="container p-4 pl-8" style={{ backgroundColor: "#f3f3f3", borderRadius: 4, paddingLeft: '60px !important', maxWidth: "840px" }}>
                <h5 className="fw-400 fs-20">
                    Cadastro contador
                </h5>
                <div className='flex gap-2 flex-col mt-1 overflow-auto'>
                    <div className='row fullwidth gap-4'>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="name">
                                CNPJ
                            </label>
                            <Controller
                                {...register('cnpj', {
                                    onChange: (e) => {
                                        const { value } = e.target;
                                        const _v = value?.replace(/\D/g, '')?.replace('-', '');
                                        console.log(_v)
                                        if (_v?.length === 14 || _v?.length === 13) {
                                            GetCNPJ(_v);
                                        }
                                    },
                                    required: {
                                        value: true,
                                        message: 'CNPJ do cliente requerido!'
                                    },
                                    validate: (value) => {
                                        if (!validarCNPJ(value)) {
                                            return 'CNPJ inválido.';
                                        }
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        mask={'__.___.___/____-__'}
                                        replacement={{ _: /\d/ }}
                                        type='text'
                                        placeholder='Digite o CNPJ'
                                    />
                                )}
                            />
                            {errors?.cnpj?.message ? <p className='text-danger'>{errors.cnpj.message}</p> : <></>}
                        </div>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Fantasia
                            </label>
                            <Controller
                                {...register('fantasia', {
                                    required: {
                                        value: true,
                                        message: 'Fantasia requerido.'
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`p-2 input-mail fullwidth`}
                                        type='text'
                                        placeholder='Fantasia'
                                    />
                                )}
                            />
                            {errors?.fantasia && <p className='text-danger'>{errors.fantasia.message}</p>}
                        </div>
                    </div>
                    <div className='row fullwidth gap-4'>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Razão Social
                            </label>
                            <Controller
                                {...register('razao_social', {
                                    required: {
                                        value: true,
                                        message: 'Razão social requerido.'
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`p-2 input-mail fullwidth`}
                                        type='text'
                                        placeholder='Razão social'
                                    />
                                )}
                            />
                            {errors?.razao_social && <p className='text-danger'>{errors.razao_social.message}</p>}
                        </div>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Email
                            </label>
                            <Controller
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: 'Email requerido!'
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`p-2 input-mail fullwidth`}
                                        type='email'
                                        placeholder='exemplo@email.com'
                                    />
                                )}
                            />
                            {errors?.email && <p className='text-danger'>{errors.email.message}</p>}

                        </div>
                    </div>
                    <div className='row fullwidth gap-4'>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                CEP
                            </label>
                            <Controller
                                {...register('cep', {
                                    onChange: (e) => {
                                        const { value } = e.target;
                                        const _v = value?.replace(/\D/g, '')?.replace('-', '');
                                        if (_v?.length === 14 || _v?.length === 8) {
                                            GetCEP(_v);
                                        }
                                    },
                                    required: {
                                        value: true,
                                        message: 'CEP requerido.'
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        mask={'_____-___'}
                                        replacement={{ _: /\d/ }}
                                        {...field}
                                        placeholder='CEP'
                                    />
                                )}
                            />
                            {errors?.cep && <p className='text-danger'>{errors.cep.message}</p>}
                        </div>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Cidade
                            </label>
                            <Controller
                                {...register('cidade')}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`p-2 input-mail fullwidth`}
                                        disabled
                                        placeholder='Cidade'
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='row fullwidth gap-4'>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Logradouro
                            </label>
                            <Controller
                                {...register("logradouro", {})}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`p-2 input-mail fullwidth`}
                                        type="text"
                                        placeholder="Logradouro"
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Complemento
                            </label>
                            <Controller
                                {...register("complemento", {})}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`p-2 input-mail fullwidth`}
                                        type="text"
                                        placeholder="Complemento"
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='row fullwidth gap-4'>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Bairro
                            </label>
                            <Controller
                                {...register("bairro", {
                                    required: {
                                        value: true,
                                        message: "Bairro requerido."
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <div>
                                        <input
                                            {...field}
                                            className={`p-2 input-mail fullwidth`}
                                            type="text"
                                            placeholder="Bairro"
                                        />
                                        {errors?.bairro && <p className='text-danger'>{errors.bairro.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Número
                            </label>
                            <Controller
                                {...register("numero", {})}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`p-2 input-mail fullwidth`}
                                        type="text"
                                        placeholder="Número"
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='row fullwidth gap-4'>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Celular
                            </label>
                            <Controller
                                {...register("celular", {
                                    required: {
                                        value: true,
                                        message: "Celular requerido!"
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        mask={'(__) _ ____-____'}
                                        replacement={{ _: /\d/ }}
                                        type="text"
                                        placeholder="(00) 9 9999-0000"
                                    />
                                )}
                            />
                            {errors?.celular && <p className='text-danger'>{errors.celular.message}</p>}
                        </div>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Contato
                            </label>
                            <Controller
                                {...register("contato", {
                                    required: {
                                        value: true,
                                        message: "Contato requerido!"
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        type="text"
                                        placeholder="Contato"
                                    />
                                )}
                            />
                            {errors?.contato && <p className='text-danger'>{errors.contato.message}</p>}

                        </div>
                    </div>
                    <div className='row fullwidth gap-4'>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="name">
                                CRC
                            </label>
                            <Controller
                                {...register('crc', {
                                    required: {
                                        value: true,
                                        message: "CRC requerido!"
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        type='text'
                                        placeholder='Digite o CRC'
                                    />
                                )}
                            />
                            {errors?.crc?.message ? <p className='text-danger'>{errors.crc.message}</p> : <></>}
                        </div>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="name">
                                Senha
                            </label>
                            <Controller
                                {...register('senha', {
                                    required: {
                                        value: true,
                                        message: "Senha requerido!"
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <div className={`input-group`}>
                                        <input
                                            {...field}
                                            type={showPass ? 'text' : 'password'}
                                            className='p-2 input-mail'
                                            placeholder='Iforme uma senha'
                                        />
                                        <span
                                            onClick={() => setShowPass(!showPass)}
                                            style={{
                                                border: "0.0625rem solid #dbd6d1",
                                                width: 30,
                                                borderTopRightRadius: '.5rem',
                                                borderBottomRightRadius: '.5rem',
                                                backgroundColor: "white"
                                            }}
                                            className="input-group-text-end-icon flex items-center justify-center pointer">
                                            {showPass ? <FiEyeOff /> : <FiEye />}
                                        </span>
                                    </div>
                                )}
                            />
                            {errors?.crc?.message ? <p className='text-danger'>{errors.crc.message}</p> : <></>}
                        </div>
                    </div>
                    <div className='fullwidth mt-1 p-1'>
                        <textarea
                            style={{ minHeight: 100, width: "calc(100% - 1.5rem)" }}
                            rows={2}
                            maxLength={250}
                            value={observacao}
                            onChange={(event) => setObservacao(event.target.value)}
                            placeholder="Observações"
                            className='input-mail'
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: 25, justifyContent: 'flex-end' }}>
                    <button
                        onClick={handleSubmit(handleCreate)}
                        className={`p-2 mt-2 btn br-2 text-center ${loading ? "c-default" : "pointer"} fs-13 fw-400`}
                        style={{ backgroundColor: loading ? "#ababab" : firstColor, color: "white", maxWidth: 200 }}>
                        {loading ? <Loading /> : "Cadastrar"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RegisterAccoutant;